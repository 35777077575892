<template>
  <div class="card border-0 bg-light">
    <div class="card-body">
      <div class="d-flex flex-wrap align-items-center">
        <i class="fe fe-server font-size-30 mr-3 text-gray-5" />
        <div class="text-dark">
          <div class="font-size-21 font-weight-bold">SP2D Diterima BPRS</div>
          <div class="font-size-15">Total: 78,155</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'KitGeneral21v2',
}
</script>
