<template>
  <div :class="$style.carousel">
    <a-carousel>
      <div class="height-150 pt-4 pl-4 overflow-hidden position-relative bg-light line-height-1">
        <h2 class="font-size-24 font-weight-bold">Finish Proses</h2>
        <a class="font-size-18" href="javascript:void(0)">12 View Report</a>
        <i class="fe fe-server text-gray-4" :class="$style.icon" />
      </div>
      <div class="height-150 pt-4 pl-4 overflow-hidden position-relative bg-light line-height-1">
        <h2 class="font-size-24 font-weight-bold">Dalam Proses</h2>
        <a class="font-size-18" href="javascript:void(0)">5 Instansi</a>
        <i class="fe fe-settings text-gray-4" :class="$style.icon" />
      </div>
    </a-carousel>
  </div>
</template>
<script>
export default {
  name: 'KitGeneral20v1',
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
