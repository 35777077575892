<template>
  <div>
    <div class="rounded overflow-hidden position-relative">
      <img class="img-fluid" src="@/assets/icons/ternate-center-city.jpg" alt="Ibu Kota Ternate" />
      <div class="font-weight-bold text-white" :class="$style.headerText">
        Mewujudkan Kota Ternate
        <br />Yang Mandiri Dan Berkeadilan
      </div>
    </div>
    <div class="d-flex flex-column flex-sm-row">
      <div class="text-center pl-4 pr-5 flex-shrink-0" :class="$style.user">
        <div
          class="kit__utils__avatar kit__utils__avatar--rounded kit__utils__avatar--size84 border border-5 border-white d-inline-block"
        >
          <img src="@/assets/icons/ternate-logo.jpg" alt="Ternate City" />
        </div>
        <div class="font-size-14 font-weight-bold">Ternate City</div>
        <a href="javascript:void(0)" class="font-size-14 text-gray-5">@ternate</a>
      </div>
      <p class="pt-3 mb-0">
        Terletak antara 3° Lintang Utara dan 3° Lintang Selatan serta 124-129° Bujur Timur. Wilayah Kota Ternate di sebelah utara, selatan dan barat berbatasan dengan Laut Maluku, dan di sebelah timur berbatasan dengan Selat Halmahera.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'KitGeneral19',
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
