<template>
  <div>
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>KEUANGAN DAERAH - PEMERINTAH KOTA TERNATE</strong>
            </div>
            <div class="text-muted">
              Sistem Pendukung Integrasi Sistem Informasi Pemerintah Daerah
              (SIPD)
            </div>
          </div>
          <div class="card-body">
            <cui-general-19 />
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>JUMLAH PENGGUNAAN DANA DAERAH</strong>
            </div>
            <span class="mr-2">
              <span class="kit__utils__donut kit__utils__donut--primary"></span>
              Income
            </span>
            <span class="mr-2">
              <span class="kit__utils__donut kit__utils__donut--success"></span>
              Outcome
            </span>
          </div>
          <div class="card-body">
            <vue-chartist
              class="height-300 mt-4"
              type="Bar"
              :data="monthCartistData"
              :options="monthChartistOptions"
            />
          </div>
        </div>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>REKAPITULASI DATA KEUANGAN</strong>
            </div>
            <div class="text-muted">
              Data keuangan terkait dinas-dinas yang masih dalam proses
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6">
                <div class="overflow-hidden rounded card border-0 mb-0">
                  <cui-general-20 />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="overflow-hidden rounded card border-0 mb-0">
                  <cui-general-20v1 />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card mt-2">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>PROGRESS PENCAIRAN DANA</strong>
            </div>
            <div class="text-muted">
              Rekap progress pencairan dana langkah demi langkah
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6">
                <cui-general-21 />
                <cui-general-21v1 />
              </div>
              <div class="col-lg-6">
                <cui-general-21v2 />
                <cui-general-21v3 />
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>PROGRES DINAS</strong>
            </div>
            <div class="text-muted">
              Daftar Dinas dalam proses pencairan dana
            </div>
          </div>
          <div class="card-body">
            <div>
              <strong>{{ progressGroup.first.name }}</strong>
              <p class="text-muted mb-1">
                {{ progressGroup.first.description }}
              </p>
              <div class="mb-3">
                <a-progress
                  :percent="progressGroup.first.progress"
                  :status="progressGroup.first.status"
                />
              </div>
              <strong>{{ progressGroup.second.name }}</strong>
              <p class="text-muted mb-1">
                {{ progressGroup.second.description }}
              </p>
              <div class="mb-3">
                <a-progress
                  :percent="progressGroup.second.progress"
                  :status="progressGroup.second.status"
                />
              </div>
              <strong>{{ progressGroup.third.name }}</strong>
              <p class="text-muted mb-1">
                {{ progressGroup.third.description }}
              </p>
              <div class="mb-3">
                <a-progress
                  :percent="progressGroup.third.progress"
                  :status="progressGroup.third.status"
                />
              </div>
              <strong>{{ progressGroup.fourth.name }}</strong>
              <p class="text-muted mb-1">
                {{ progressGroup.fourth.description }}
              </p>
              <div class="mb-3">
                <a-progress
                  :percent="progressGroup.fourth.progress"
                  :status="progressGroup.fourth.status"
                />
              </div>
              <strong>{{ progressGroup.fives.name }}</strong>
              <p class="text-muted mb-1">
                {{ progressGroup.fives.description }}
              </p>
              <div class="mb-3">
                <a-progress :percent="progressGroup.fives.progress" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Calendar</strong>
            </div>
            <div class="text-muted">Block with important Calendar information</div>
          </div>
          <div class="card-body">
            <a-calendar>
              <ul class="events" slot="dateCellRender" slot-scope="value">
                <li v-for="item in getListData(value)" :key="item.content">
                  <a-badge :status="item.type" :text="item.content" />
                </li>
              </ul>
              <template slot="monthCellRender" slot-scope="value">
                <div v-if="getMonthData(value)" class="notes-month">
                  <section>{{getMonthData(value)}}</section>
                  <span>Backlog number</span>
                </div>
              </template>
            </a-calendar>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="mb-4">
              <cui-general-22 />
            </div>
            <a-table
              :columns="tableColumns"
              :dataSource="tableData"
              :pagination="false"
              class="kit__utils__table"
            >
              <div
                slot="filterDropdown"
                slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
                class="custom-filter-dropdown"
              >
                <a-input
                  v-ant-ref="c => searchInput = c"
                  :placeholder="`Search ${column.dataIndex}`"
                  :value="selectedKeys[0]"
                  @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                  @pressEnter="() => handleSearch(selectedKeys, confirm)"
                  style="width: 188px; margin-bottom: 8px; display: block;"
                />
                <a-button
                  type="primary"
                  @click="() => handleSearch(selectedKeys, confirm)"
                  icon="search"
                  size="small"
                  style="width: 90px; margin-right: 8px"
                >Search</a-button>
                <a-button
                  @click="() => handleReset(clearFilters)"
                  size="small"
                  style="width: 90px"
                >Reset</a-button>
              </div>
              <a-icon
                slot="filterIcon"
                slot-scope="filtered"
                type="search"
                :style="{ color: filtered ? '#108ee9' : undefined }"
              />
              <template slot="customRender" slot-scope="text">
                <span v-if="searchText">
                  <template
                    v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
                  >
                    <mark
                      v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                      :key="i"
                      class="highlight"
                    >{{fragment}}</mark>
                    <template v-else>{{fragment}}</template>
                  </template>
                </span>
                <template v-else>{{text}}</template>
              </template>
          <template slot="formatCurrency" slot-scope="text">
            {{ formatCurrency(text) }}
          </template>
            </a-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import CuiGeneral19 from './viewcomponents/19'
import CuiGeneral20 from './viewcomponents/20'
import CuiGeneral20v1 from './viewcomponents/20v1'
import CuiGeneral21 from './viewcomponents/21'
import CuiGeneral21v1 from './viewcomponents/21v1'
import CuiGeneral21v2 from './viewcomponents/21v2'
import CuiGeneral21v3 from './viewcomponents/21v3'
import CuiGeneral22 from './viewcomponents/22'
import VueChartist from 'v-chartist'

export default {
  created () {
    this.getMaster()
  },
  components: {
    'vue-chartist': VueChartist,
    CuiGeneral19,
    CuiGeneral20,
    CuiGeneral20v1,
    CuiGeneral21,
    CuiGeneral21v1,
    CuiGeneral21v2,
    CuiGeneral21v3,
    CuiGeneral22,
  },
  data() {
    return {
      searchText: '',
      searchInput: null,
      progressGroup: {
        first: {
          name: 'Dinas Pertanian:',
          description: 'Menunggu Konfirmasi',
          progress: 60,
          status: 'active',
        },
        second: {
          name: 'Dinas Kelautan Dan Perikanan:',
          description: 'Dalam Proses input data',
          progress: 30,
          status: 'active',
        },
        third: {
          name: 'Dinas Perhubungan:',
          description: 'Perbaikan Data',
          progress: 46,
          status: 'exception',
        },
        fourth: {
          name: 'Dinas Sosial:',
          description: 'Telah DiKonfirmasi',
          progress: 80,
          status: 'active',
        },
        fives: {
          name: 'Dinas Tenaga Kerja:',
          description: 'Selesai',
          progress: 100,
          status: 'success',
        },
      },

      // Chart Properties
      monthChartistOptions: {
        seriesBarDistance: 10,
      },
      monthCartistData: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        series: [
          [5, 4, 3, 7, 5, 10, 3, 4, 8, 10, 6, 8],
          [3, 2, 9, 5, 4, 6, 4, 6, 7, 8, 7, 4],
        ],
      },
      // End

      // Table Properties
      tableColumns: [
        {
          title: 'Instansi',
          dataIndex: 'instansi',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) => record.instansi.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'NIP',
          dataIndex: 'nip',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) => record.nip.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'NIK',
          dataIndex: 'nik',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) => record.nik.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Nama',
          dataIndex: 'nama',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) => record.nama.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Gaji',
          dataIndex: 'gaji_pokok',
          sorter: (a, b) => a.gaji_pokok - b.gaji_pokok,
          scopedSlots: {
            customRender: 'formatCurrency',
          },
        },
        {
          title: 'Kelamin',
          dataIndex: 'kelamin_label',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) => record.kelamin_label.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Tempat Lahir',
          dataIndex: 'tempat_lahir',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) => record.tempat_lahir.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Tanggal Lahir',
          dataIndex: 'tgl_lahir',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) => record.tgl_lahir.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Alamat',
          dataIndex: 'alamat',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) => record.alamat.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Status Nikah',
          dataIndex: 'status_nikah_label',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) => record.status_nikah_label.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Golongan',
          dataIndex: 'golongan',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) => record.golongan.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
      ],
      tableData: [],
      backupData: [],
      datainstansi: [],
      datagolongan: [],
    }
  },
  methods: {
    handleSearch(selectedKeys, confirm) {
      confirm()
      this.searchText = selectedKeys[0]
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    getListData(value) {
      let listData
      // console.log('value.date', value.date)
      switch (value.date()) {
        case 8:
          listData = [
            { type: 'warning', content: 'Dinas Pertanian.' },
            { type: 'success', content: 'Cair Dana Dinas Tenaga Kerja' },
          ]; break
        case 10:
          listData = [
            { type: 'warning', content: 'Dinas Perhubungan.' },
            { type: 'success', content: 'Dinas Perindustrian.' },
            { type: 'error', content: 'Cair Dana Dinas Perhutanan.' },
          ]; break
        case 15:
          listData = [
            { type: 'warning', content: 'Dinas Pendidikan.' },
            { type: 'success', content: 'Dinas Pengendalian.' },
            { type: 'error', content: 'Dinas Kependudukan.' },
          ]; break
        default:
      }
      return listData || []
    },
    getMonthData(value) {
      if (value.month() === 8) {
        return 1394
      }
    },
    async getMaster() {
      var res = await lou.customUrlGet2('instansi', false, false)
      var res1 = await lou.customUrlGet2('golongan', false, false)
      if (res) {
        this.datainstansi = res.data
      }
      if (res1) {
        this.datagolongan = res1.data
      }
      this.getData()
    },
    async getData() {
      var res = await lou.customUrlGet2('pegawai', false, false)
      if (res) {
        this.tableData = []
        var rowdata = []
        res.data.forEach(element => {
          var tdata = {
            instansi: 'Instansi tidak terdaftar',
            golongan: 'Golongan tidak terdaftar',
            gaji_pokok: '-',
            status_nikah_label: '',
            kelamin_label: 'Jenis Kelamin tidak terdaftar',
            ...element,
          }
          var targetIndexinstansi = this.datainstansi.findIndex(x => x.id === element.instansi_id)
          if (targetIndexinstansi !== -1) {
            tdata.instansi = this.datainstansi[targetIndexinstansi].keterangan
          }
          var targetIndexgolongan = this.datagolongan.findIndex(x => x.id === element.golongan_id)
          if (targetIndexgolongan !== -1) {
            tdata.golongan = this.datagolongan[targetIndexgolongan].keterangan
            tdata.gaji_pokok = this.datagolongan[targetIndexgolongan].gaji_pokok
            // console.log('this.datagolongan[targetIndexgolongan]', this.datagolongan[targetIndexgolongan])
          }
          if (element.status_nikah === 'S') {
            tdata.status_nikah_label = 'Sudah menikah'
          } else if (element.status_nikah === 'B') {
            tdata.status_nikah_label = 'Belum menikah'
          } else {
            tdata.status_nikah_label = 'Status tidak jelas'
          }
          if (element.kelamin === 'L') {
            tdata.kelamin_label = 'Laki-Laki'
          } else if (element.kelamin === 'P') {
            tdata.kelamin_label = 'Perempuan'
          } else {
            tdata.kelamin_label = 'Kelamin tidak jelas'
          }
          rowdata.push(tdata)
        })
        this.tableData = this.$g.clone(rowdata)
        this.backupData = this.$g.clone(rowdata)
      }
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
  },
}
</script>

<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}

.events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
.notes-month {
  text-align: center;
  font-size: 28px;
}
.notes-month section {
  font-size: 28px;
}
</style>
